<template>
    <v-dialog v-model="show"
              content-class="mt-shedule__versi"
              scrollable
              width="640">
        <v-card>
            <v-card-title>
                Версии расписаний
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-subheader>выберите необходимую версию расписания</v-subheader>
                    <v-list-item v-for="v in versi"
                                 :key="v.id"
                                 v-on:click="generate(v)">
                        <v-row>
                            <v-col cols="2">
                                {{ v.version }}
                                <div class="ver-dt">
                                    {{ format(v.verStart) }}
                                </div>
                            </v-col>
                            <v-col>{{ v.verStatusName }}</v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn small outlined v-on:click="show = false">
                    <v-icon small>mdi-close</v-icon>&nbsp;закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import BaseAction from '@/components/actions/BaseAction';
import RouteService from '@/services/RouteService';
import ReportService from '@/components/services/ReportService';
import Report from '@/core/Report';

export default {
  mixins: [
    BaseAction
  ],
  name: 'OpenReport_10_2',
  data(){
        return { 
            show: false,
            loading: false,
            versi: []
        };
  },
  mounted() {
    if (!this.selected) {
      return;
    }
    this.open();
  },
  methods: {
        format(dt){
            let m = $moment(dt);
            return m.isValid() ? m.format("DD.MM.YYYY") : '';
        },
        async generate(verShedule){
            const r = new Report({
                id: "88d5c390-9ca8-442b-9edc-20562d12c213",
                uri:  "report:/88d5c390-9ca8-442b-9edc-20562d12c213/",
                reportDescription: {
                    title: "10.2 Видетельство об осуществлении регулярных перевозок",
                    formats: [{mime: "application/vnd.ms-word.document.macroEnabled.12"}]
                }
            });
            try {
                await r.call([
                        {
                            name: "certificateID",
                            type: "id",
                            value: this.selected.vccertificateregisterId
                        },
                        {
                            name: "scheduleVersionID",
                            type: "id",
                            value: verShedule.id
                        }
                ]);
                this.show = false;
            } catch(e){
                jet.msg({text: `ВНИМАНИЕ: Ошибка формирования отчета: ${ e.message } ${ e.data || '' }`, color: "warning"});
            }
        },
        async open() {
            this.loading = true;
            let routeId = this.selected.vccertificateregisterServicedrouteidRoute;
            let query = `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=
                                eq(field(".route"),param("${ routeId }","id"))
                                &sort=-vcReleaseSchedules.verStart`;
            try {
                let res = await jet.http.post({
                    type: 'core-read',
                    query,
                    transform: true
                });
                console.log('vers', res);
                if (res.error){
                    throw res.error;
                }
                this.versi = res;
                if ( 1 === res.length ){
                    this.generate(res[0]);
                } else {
                    this.show = true;
                }
            } catch(e){
                console.log('ERR (vers)', e);
                jet.msg({
                  text: `ВНИМАНИЕ! Ошибка получения версий расписаний по выбранному маршруту:<div class="small">${ e.message } ${ e.data || ''}</div>`,
                  color: 'warning'
                });
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .mt-shedule__versi{
        & .v-list{
            &-item{
                &:not(:last-child){
                    border-bottom: 1px solid #ccc;
                }
                & .row{
                    align-items: center;
                    & .col:first-child{
                        font-size: 1.25rem;
                        text-align: center;
                        & .ver-dt{
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }
    }
</style>